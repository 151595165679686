const loadSumoSelect = (select) => {
  try {
    $(select).SumoSelect({
      placeholder: 'Selecione aqui',
      captionFormat: '{0} Selecionados',
      captionFormatAllSelected: '{0} Todos selecionados',
    })

    $(select).on('sumo:opened', function (sumo) {
      const parent = $(sumo.target).parents('.dse-select')
      $(parent).addClass('dse-select__open')
    })

    $(select).on('sumo:closed', function (sumo) {
      const parent = $(sumo.target).parents('.dse-select')
      $(parent).removeClass('dse-select__open')
    })
  } catch (err) {
    console.log(err)
  }
}

const generateSelect = () => {
  const wrapper = `  
  <div class="dse-input__wrapper">
    <div class="dse-input__view"></div>
        <fieldset>
          <legend><span>Teste!</span></legend>
        </fieldset>
    <div class="dse-input__helper"></div>
  </div>
    `

  $('.dse-select').each((_, content) => {
    const clone = $(content).find('select').clone()
    $(content).html('')
    $(content).append(wrapper)
    $(content).find('.dse-input__view').append(clone);

    const select = $(content).find('select')

    const labelOption = $(content).find("select > option[disabled]");
    const placeholder = $(labelOption).text();

    $(content).find("fieldset span").html(placeholder);

    setTimeout(() => {
      loadSumoSelect(select);
    }, 0)
  })
}
export default generateSelect
