export default {
  errorPlacement: function(error, element) {
    console.log('-- errorPlacement -- ')
    const parent = $(element).parent().parent().parent();

    const textHelper = parent[0].querySelector('.dse-input__helper')
    $(textHelper).html(error)
  },

  highlight: function(element, errorClass, validClass) {
    console.log('-- highlight -- ')
    const parent = $(element).parent().parent().parent();
    $(parent).addClass('field-error');
  },

  unhighlight: function(element, errorClass, validClass) {
    console.log('-- unhighlight -- ')
    const parent = $(element).parent().parent().parent()
    $(parent).removeClass('field-error')
  }
}
