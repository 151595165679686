// const modules = import.meta.glob('@/javascript/lib/*.js', { eager: true })
import Rails from '@rails/ujs';
import '@/javascript/lib/svg-inject.min.js'
import '@/javascript/lib/owl.carousel.min.js'
import '@/javascript/lib/jquery-modal-video.min.js'
import * as jquery_sumoselect from '@/javascript/lib/jquery.sumoselect.min.js'
import * as jquery_validation from '@/javascript/lib/jquery.validation.js'
import * as jquery_mask from '@/javascript/lib/jquery.mask.js'
import lazyLoad from '@/javascript/lib/lazysizes.js'
import log from '@/javascript/functions/log.js'
import formValidationConfig from '@/javascript/new_site/modules/formValidationConfig.js'
import generateSelect from '@/javascript/new_site/modules/formSelect.js'
import formatInputOptions from '@/javascript/new_site/modules/formatInputOptions.js'
import vex from 'vex-js'
import '@/javascript/application/vex-utils'

window.log = log
window.vex = vex
window.generateSelect = generateSelect
window.vex.defaultOptions.className = 'vex-theme-default';
Rails.start()
window.jquery_sumoselect = jquery_sumoselect
window.jquery_validation = jquery_validation
window.jquery_mask = jquery_mask

// load pages js based on the data-js-page attribute
window.addEventListener('load', () => {
  let page = document.querySelector('[data-js-page]').dataset.jsPage

  // switch is required here as I cannot set `${page}` on the import
  switch (page) {
    case 'home':
      import('@/javascript/new_site/pages/home').then((module) => {
        module.default()
      })
      break;
    case 'contact':
      import('@/javascript/new_site/pages/contact').then((module) => {
        module.default()
      })
      break;
    case 'request_your_video':
      import('@/javascript/new_site/pages/request_your_video').then((module) => {
        module.default()
      })
      break;
    case 'become_partner':
      import('@/javascript/new_site/pages/become_partner').then((module) => {
        module.default()
      })
      break;
    case 'my_page_index':
      import('@/javascript/new_site/pages/my_page_index').then((module) => {
        module.default()
      })
      break;
    case 'my_page':
      import('@/javascript/new_site/pages/my_page').then((module) => {
        module.default()
      })
      break;
    case 'login':
      import('@/javascript/new_site/pages/login').then((module) => {
        module.default()
      })
      break;
    case 'screenplay':
      import('@/javascript/new_site/pages/screenplay').then((module) => {
        module.default()
      })
      break;
    case 'sign-up':
      import('@/javascript/new_site/pages/sign-up').then((module) => {
        module.default()
      })
      break;
    case 'new-password':
      import('@/javascript/new_site/pages/new-password').then((module) => {
        module.default()
      })
      break;
    default:
      log(`module ${page} not found!`)
      break
    }
})

const addedFocusInputHandler = () => {
  const inputType = '.dse-input__field'
  $(inputType).focus(function () {
    const parent = $(this).parent().parent()
    const val = parent.find('.dse-input__label:eq(0)').text()
    parent.find('legend > span').html(val)
    parent.addClass('dse-input--focus')
  })

  $(inputType).blur(function () {
    const val = $(this).val()
    const parent = $(this).parent().parent()

    if (val.length) {
      parent.addClass('dse-input--filled')
    } else {
      parent.removeClass('dse-input--filled')
    }

    parent.removeClass('dse-input--focus')
  })
}

const owlCarouselInit = () => {
  const video = $('.owl-carousel.card-videos').owlCarousel({
    center: true,
    items: 2,
    loop: false,
    margin: 10,
    nav: false,
    autoWidth: true,
    responsive: {
      600: {
        items: 4,
        nav: false,
      },

      1000: {
        loop: true,
        autoWidth: true,
        items: 3,
        center: false,
      },
    },
  })

  $('.carousel-next.video-control').click(function () {
    video.trigger('next.owl.carousel')
  })
  // Go to the previous item
  $('.carousel-prev.video-control').click(function () {
    video.trigger('prev.owl.carousel')
  })

  const testimonial = $('.owl-carousel.testimonial').owlCarousel({
    loop: true,
    margin: 10,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  })

  $('.carousel-next.testimonial-control').click(function () {
    testimonial.trigger('next.owl.carousel')
  })
  // Go to the previous item
  $('.carousel-prev.testimonial-control').click(function () {
    testimonial.trigger('prev.owl.carousel')
  })
}

const loadFancyBox = () => {
  $('.modal').modalVideo({ channel: "vimeo" })
}

const accordion = () => {
  $('.set > a').on('click', function () {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active')
      $(this).siblings('.content').slideUp(200)
      $('.set > a i').removeClass('fa-minus').addClass('fa-plus')
    } else {
      $('.set > a i').removeClass('fa-minus').addClass('fa-plus')
      $(this).find('i').removeClass('fa-plus').addClass('fa-minus')
      $('.set > a').removeClass('active')
      $(this).addClass('active')
      $('.content').slideUp(200)
      $(this).siblings('.content').slideDown(200)
    }
  })
}

const scrollDirections = () => {
  let lastScrollTop = 0
  $('html body').scroll(function (event) {
    let st = $(this).scrollTop()

    if (st > 60) {
      $('.header').addClass('fixed-header')
    } else {
      $('.header').removeClass('fixed-header')
    }

    if (st > lastScrollTop) {
      $('.header').removeClass('up')
    } else {
      $('.header').addClass('up')
    }
    lastScrollTop = st
  })
}

const activeNavMenu = () => {
  const page = window.location.pathname
  if (page) {
    $(`nav a[href="${page}"]`).addClass('active-page')
  }
}

const inputGenerator = () => {
  const showPassword = (el) => {
    const type = $(el).attr('type')
    $(el).attr('type', type === 'password' ? 'text' : 'password')
  }

  $('.dse-input').each((_, content) => {
    const label = $(content).find('label:eq(0)').addClass('dse-input__label')
    let input = $(content).find('input').clone()
    const textarea = $(content).find('textarea').clone()

    if (textarea.length) {
      input = textarea
    }

    const icon = $(content)
    .find('.icon')
    .clone()
    .removeClass('icon')
    .addClass('dse-input__icon')

    input.addClass('dse-input__field')
    label.addClass('dse-input__label')

    if (icon.hasClass('show-password')) {
      $(icon).click(() => {
        showPassword(input)
        $(icon).toggleClass('show-password--active')
      })
    }

    const wrapper = `  
    <div class="dse-input__wrapper">
        <div class="dse-input__view"></div>
        <fieldset>
          <legend><span>${label.html()}</span></legend>
        </fieldset>
        <div class="dse-input__helper"></div>
    </div>
    `

    $(content).html('')
    $(content).append(wrapper)
    if (textarea.length) {
      $(content).find('.dse-input__wrapper').addClass('dse-input-textarea')
    }
    if ($(input).data('autofill')) {
      $(content).find('.dse-input__wrapper').addClass('dse-input--filled')
    }
    $(content).find('.dse-input__view').append(label)
    $(content).find('.dse-input__view').append(input)
    $(content).find('.dse-input__view').append(icon)
    const value = $(content).find('input').val()
    if (value && value.length) {
      $(content).find('.dse-input__wrapper').addClass('dse-input--filled');
    }
    //$(content).find('input').focus();
    setTimeout(() => $(content).find('input').blur(), 100)
    generateSelect()
    formatInputOptions()
  })
}

const generateTooltips = () => {
  $(".tooltip").each((_, item) => {
    const text = ($(item).data("tooltip"));

    tippy(item, {
      content: text,
    })
  })
}

function hubSpotInteract() {
  const select_id = '#select-footer'
  const hubForm = $('.hbspt-form')

  const submitButton = $('#news-letter-submit')
  const emailInput = $(hubForm).find("input[type='email']")
  const previousSubmitButtonValue = $(submitButton).html()
  const emailVal = $('#newsletter-email').val()
  let obj = {}

  const getSelecteds = () => {
    $(`${select_id} option:selected`).each(function (i) {
      obj[$(this).val()] = $(this).val()
    })

    $(submitButton).html('<img src="https://www.easymovie.com.br/assets/new_site/tick-circle-37e0319d3417ec5ab5da7f9eadf92a93a7ad0af6ec6e85b6917a91b2e37bbafe.svg" width="20" height="20"> &nbsp Enviado')

    setTimeout(() => {
      $(submitButton).html(previousSubmitButtonValue)
    }, 3000)
  }

  $(emailInput).val(emailVal).change()
  getSelecteds();

  const data = {
    email: emailVal,
    options: obj
  }

  myIframe.contentWindow.postMessage(JSON.stringify(data), '*')
  return false
}

$(document).ready(() => {
  activeNavMenu()

  owlCarouselInit()

  accordion()

  loadFancyBox()

  scrollDirections()

  lazyLoad()

  $('#dse-curtain')
    .delay(300)
    .animate({ opacity: 0 }, 1000, function () {
      $('#dse-curtain').css('display', 'none');
      generateTooltips();
    })

  setTimeout(() => {
    try {
      $('.svg-icon').each((_, item) => SVGInject(item) )
    } catch (err) {
    }
    inputGenerator()
    addedFocusInputHandler()
  }, 200)

  $('#news-letter-form').validate({
    ...formValidationConfig.default,
    messages: {
      'newsletter-email': {
        required: 'Digite seu e-mail',
        email: 'E-mail inválido',
      },
    },
    submitHandler() {
      hubSpotInteract()
    },
  })

  bindVexCloseSelfEvents();

  //Toggle menu mobile
  $('#navi-toggle').click(() => {
    $('.navbar').toggleClass('active-menu')
  })
})

// not working on the css
// $('.auto-scroll').click(function (_) {
//   let href = $(this).attr("href");
//
//   $('html, body').animate({
//     scrollTop: $(href).offset().top
//   }, 1000);
//   return false;
// });

log("static_site.js loaded")
