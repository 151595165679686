const formInputRadio = (content) => {
  const label = $(content).find('label:eq(0)')
  const input = $(content).find('input').clone()

  const wrapper = ` 
  <div>
    <div class="dse-input__wrapper">
      <div class="dse-input__checkbox">
        <label class="cbx" for="${label.attr('for').trim()}" title="${label.text().trim()}">
          <span>
            <svg viewbox="0 0 12 9">
              <polyline points="1 5 4 8 11 1"></polyline>
            </svg>
          </span>
          <span class="dse-input__option-text">${label.text().trim()}</span>
        </label>
        <div class="dse-input__helper"></div>
      </div>
    </div>
  </div>
`
  const element = $(content)

  element.html('')
  element.append(wrapper)
  element.find('.dse-input__checkbox').prepend(input.addClass('inp-cbx'))
}

const formatInputOptions = () => {
  $('.dse-input-option').each((index, element) => {
    const type = $(element).find('input').attr('type')
    if (type === 'checkbox') formInputRadio(element)
  })
}
export default formatInputOptions
